import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import shouldShowInmateNumber from '../../functions/shouldShowInmateNumber';
import { IFacility } from '../../models/Facility';
import { IInmate } from '../../models/Inmate';
import ErrorMessage from '../ErrorMessage';
import { ButtonSize, renderActionSubmit } from '../Form/RenderForm';
import { formatPhoneNumber } from '../Format/PhoneNumber';

interface IInmatePickerProps {
  facility: IFacility
  loadingInmates: boolean
  inmates: IInmate[]
  onSelect(inmate: IInmate): void
  selectedInmate: IInmate
  warnIfNoDevice?: boolean
}

function getInmateName(inmate: IInmate): string {
  if ((inmate.middleName || '').length > 0) {
    return `${inmate.firstName} ${inmate.middleName.substr(0, 1)} ${inmate.lastName}`;
  }

  return `${inmate.firstName} ${inmate.lastName}`;
}

const InmatePicker: React.FC<IInmatePickerProps> = props => {
  const [selected, selectInmate] = useState(null);

  return (
    <>
      <h3>Search for inmate by last name</h3>
      <div className="row my-lg-5 no-gutters">
        {props.selectedInmate && props.selectedInmate.status == 'Disabled' &&
          <ErrorMessage message="This inmate is either released or unable to receive wallet funds at this time." />
        }

        <div className="form-label-group col-lg-8">
          <Typeahead
            disabled={props.loadingInmates}
            inputProps={{
              className: "search"
            }}
            id="searchInmate"
            placeholder={props.loadingInmates ? "Loading inmates..." : "Enter Inmate's Name"}
            defaultSelected={props.selectedInmate == null ? undefined : [{ id: props.selectedInmate.id, label: `${props.selectedInmate.firstName} ${props.selectedInmate.lastName}`}]}
            onChange={selectedInmates => {
              if (selectedInmates == null) {
                selectInmate(null)
              } else if (selectedInmates.length > 0) {
                selectInmate(props.inmates.filter(i => i.id == selectedInmates[0].id)[0])
              }
            }}
            options={props.inmates ? props.inmates.map(i => ({
              id: i.id,
              label: getInmateName(i)
            })) : []} />
        </div>
        <div className="col-lg-3 offset-lg-1 form-button-action">
          {renderActionSubmit(null, props.loadingInmates ? 'Loading...' : 'Continue', 'Continue', ButtonSize.Large, false, (e) => {
            props.onSelect(selected);
            e.preventDefault();
            return false;
          }, props.loadingInmates || selected == null)}
        </div>

        {selected && shouldShowInmateNumber(props.facility, selected) &&
          <p>Text {selected.firstName} {selected.lastName} at <a href={`sms:+${selected.device.smsNumber.number}`}>{formatPhoneNumber(selected.device.smsNumber.number)}</a>.</p>
        }

        {selected && selected.device == null && props.warnIfNoDevice &&
          <ErrorMessage severity="info" message="This inmate does not currently have a mobile device, but you may add funds to their account." />
        }
      </div>
    </>
  )
}

export default InmatePicker
